// Button.jsx
import React from 'react'
import { Loading2 } from './loading'
import { useAuth } from '../context/AuthContext'
import { checkPermission } from './permissions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faPenToSquare,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

const Button = ({
  type,
  onClick,
  disabled,
  title,
  className,
  btnStyle,
  rightIcon,
  loading
}: {
  loading?: boolean
  rightIcon?: any
  type?: any
  disabled?: boolean
  onClick?: any
  title?: any
  className?: any
  btnStyle?: 'btn-outline' | 'btn-primary' | 'btn-blue'
}) => {
  return (
    <button
      disabled={disabled || loading}
      type={type || 'button'}
      onClick={onClick}
      className={`align-items-center align-center flex justify-center rounded-md px-4 py-2 ${className} hover:opacity: 12 ${btnStyle === 'btn-outline' ? 'border border-slate-500 text-slate-500' : ''} ${(disabled || loading) && 'opacity-50'} ${btnStyle === 'btn-blue' && `bg-[#2196F3] text-white`} ${
        btnStyle === 'btn-primary' && `'btn-primary bg- bg-primary text-white`
      } `}
    >
      {title} {rightIcon && <div className='ml-2'>{rightIcon}</div>}
      {loading && (
        <span className='ml-2'>
          <Loading2 />
        </span>
      )}
    </button>
  )
}

export default Button
export type Button2Props = {
  type?: 'button' | 'submit' | 'reset' | any
  onClick?: (data?: any) => void
  disabled?: boolean
  title?: string
  className?: string
  btnStyle?: 'outline' | 'primary' | string
  rightIcon?: any
  loading?: boolean
  model?: string
  action?: 'create' | 'update' | 'delete' | 'read' | string
}
export const Button2 = ({
  type = 'button',
  onClick,
  disabled,
  title,
  className,
  btnStyle = 'primary',
  rightIcon,
  loading,
  model,
  action
}: Button2Props) => {
  const { currentUser } = useAuth()
  if (model && action && !checkPermission(currentUser, model, action))
    return null
  return (
    <button
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      className={`mt-1.5 inline-flex w-full justify-center rounded-md bg-${btnStyle} px-4 py-2 text-sm font-semibold text-${btnStyle === 'outline' ? 'primary border border-primary' : 'white'} shadow-sm hover:opacity-75 disabled:opacity-75 sm:ml-3 sm:mt-0 sm:w-auto ${className || ''}`}
    >
      {title} {rightIcon && <div className='ml-2'>{rightIcon}</div>}{' '}
      {action === 'create' && (
        <div className='ml-2'>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
      {loading && (
        <span className='ml-2'>
          <Loading2 />
        </span>
      )}
    </button>
  )
}
type IconButtonProps = {
  onClick?: (data?: any) => void
  className?: string
  icon?: any
  model?: string
  action?: 'create' | 'update' | 'delete' | 'read' | string
}
export const IconButton = ({
  onClick,
  className,
  icon,
  model,
  action
}: IconButtonProps) => {
  const { currentUser } = useAuth()
  if (model && action && !checkPermission(currentUser, model, action))
    return null
  return (
    <FontAwesomeIcon
      onClick={onClick}
      className={
        className ||
        `${action === 'delete' ? 'text-red-500' : ''} mx-0.5 cursor-pointer hover:opacity-75`
      }
      icon={
        icon ? icon
        : action === 'delete' ?
          faTrash
        : action === 'update' ?
          faPenToSquare
        : action === 'read' ?
          faEye
        : ''
      }
    />
  )
}
