import React, { useEffect } from 'react'
import WebLayout from './webLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { ADMIN } from '../../consts'

export default function MainApp() {
  const params = useParams()
  const { pathname } = useLocation()
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const exclude = ['/', '/dashboard', '/profile', '/support', '/faq']
    if (ADMIN?.includes(currentUser.role?.name) || exclude.includes(pathname))
      return
    else if (currentUser?.permissions) {
      let path = pathname.split('/').reverse()
      path = path.filter(
        (item) => Object.values(params).includes(item) === false
      )
      let path_to_use = path[0] || path[1]
      let check = 'read'
      if (['create', 'edit', 'preview'].includes(path_to_use)) {
        check =
          path_to_use === 'create' ? 'create'
          : path_to_use === 'edit' ? 'update'
          : 'read'
        const index = path.indexOf(path_to_use)
        path_to_use = path[index + 1]
      }
      const permission = currentUser.permissions.find(
        (item: any) => item.model_name === path_to_use
      )
      if (!permission || permission[check] !== true) {
        navigate('/profile')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentUser])

  return (
    <>
      <WebLayout />
      <footer
        className={`fixed bg-white text-center text-sm w-full bottom-0 p-4`}
      >
        Copyright © {new Date().getFullYear()} Velocity Manager. All rights
        reserved
      </footer>
    </>
  )
}
