import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { UserModel, get_user_profile_api } from '../service/user'
import { settings_api } from '../service/settings'

const initialState = {
  token: localStorage.getItem('token'),
  currentUser:
    localStorage.getItem('user') ?
      JSON.parse(localStorage.getItem('user')!)
    : null,
  setToken: (token: string | null) => {},
  setCurrentUser: (user: UserModel | null) => {}
}
const AuthContext = createContext(initialState)

const AuthProvider = ({ children }: any) => {
  const [token, set_token] = useState(localStorage.getItem('token'))
  const [currentUser, set_currentUser] = useState(
    localStorage.getItem('user') ?
      JSON.parse(localStorage.getItem('user')!)
    : null
  )
  const setToken = (token: string | null) => {
    set_token(token)
    if (token) localStorage.setItem('token', token)
    else {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    }
  }
  const setCurrentUser = (user: UserModel | null) => {
    set_currentUser(user)
    if (user) localStorage.setItem('user', JSON.stringify(user))
    else localStorage.removeItem('user')
  }

  return (
    <AuthContext.Provider
      value={{ setToken, token, currentUser, setCurrentUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth: any = () => {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }

export const AuthInit: FC<{ children: ReactNode }> = ({ children }) => {
  const { token, setToken, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const data = await get_user_profile_api()
          if (data) setCurrentUser(data)
          const settings = await settings_api({ queryKey: [] })
          if (settings)
            localStorage.setItem('settings', JSON.stringify(settings))
        }
      } catch (error) {
        if (!didRequest.current) setToken()
      }
      return () => (didRequest.current = true)
    }

    if (token) requestUser()
    else setToken()
    // eslint-disable-next-line
  }, [token])

  return <>{children}</>
}
